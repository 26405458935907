import FocusTrap from 'focus-trap-react';
import { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import CloseX from 'components/icons/navigation/CloseX';
import useScreenSizeConstants from 'hooks/useScreenSizeConstants';
import * as AdaChat from 'utilities/adaChat';
import * as Types from 'utilities/types';

const toggleBodyScroll = (show, isSlideoutAlreadyOpen) => {
  if (show) {
    document.body.classList.add('overflow-hidden');
  } else if (!isSlideoutAlreadyOpen) {
    document.body.classList.remove('overflow-hidden');
  }
};

export const WIDTH_840 = 'w-840';
export const WIDTH_720 = 'w-720';
export const WIDTH_500 = 'w-500';
export const WIDTH_FULL = 'w-full';

const Slideout = ({
  backPath = '',
  closeButtonClassname = '',
  closeClassname = '',
  closeCallback = () => {},
  desktopWidth = WIDTH_720,
  isSlideoutAlreadyOpen = false,
  keyboardAvoidingFooter = false,
  renderCloseFirst = false,
  slideoutContainerClassname = '',
  updateAdaChat = true,
  children,
  customSlideoutClass,
  id,
  headerElement,
  labelId,
  scrollableRef,
  show,
}) => {
  const history = useHistory();
  const [lastFocusedEl, setLastFocusedEl] = useState(null);
  const focusOnLastEl = () => {
    if (lastFocusedEl) setTimeout(() => lastFocusedEl.focus());
  };
  const [currentSlideout, setCurrentSlideout] = useState('');
  const { isMobileOrTablet } = useScreenSizeConstants();
  const modalRef = scrollableRef || useRef();
  const overlayRef = useRef();
  const slideoutClass = customSlideoutClass || 'slideout';

  // Setting timeouts for event listeners to allow time for elements to receive/track focus
  const addKeyDownListener = () =>
    setTimeout(() => document.addEventListener('keydown', checkDocumentKeyDown));

  const removeKeyDownListener = () =>
    setTimeout(() => document.removeEventListener('keydown', checkDocumentKeyDown));

  const checkDocumentKeyDown = event => {
    if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
      navigateBack();
    }
  };

  const updateAdaChatPosition = () =>
    AdaChat.updateAdaChatPosition({ desktopWidth, keyboardAvoidingFooter, show, isMobileOrTablet });

  const addWindowResizeListener = listener => window.addEventListener('resize', listener);

  const removeWindowResizeListener = listener => window.removeEventListener('resize', listener);

  useEffect(() => {
    // Keep track of the current Slideout being rendered, so that
    // when the clean up function is triggered we can check if the clean
    // up function is being run for the correct Slideout.
    if (id !== currentSlideout) {
      setCurrentSlideout(id);
    }
  }, [id]);

  useEffect(() => {
    if (!isSlideoutAlreadyOpen) {
      if (show) {
        setLastFocusedEl(document.activeElement);
        addKeyDownListener();
        if (updateAdaChat) {
          addWindowResizeListener(updateAdaChatPosition);
          AdaChat.updateAdaChatPosition({
            desktopWidth,
            keyboardAvoidingFooter,
            show,
            isMobileOrTablet,
          });
        }
      }

      return () => {
        if (id === currentSlideout) {
          removeKeyDownListener();
          if (updateAdaChat) {
            removeWindowResizeListener(updateAdaChatPosition);
            AdaChat.resetPosition();
          }
        }
      };
    }
  }, [show, isSlideoutAlreadyOpen]);

  const onClose = () => {
    closeCallback();
    focusOnLastEl();
  };

  const navigateBack = () => {
    onClose();
    if (backPath) history.push(backPath);
  };

  const renderDefaultHeader = () => {
    const textClassNames =
      'appearance-none border-none text-charcoal-100 hover:text-charcoal-80 focus:text-charcoal-80 bg-transparent';
    if (backPath)
      return (
        <Link
          to={backPath}
          onClick={onClose}
          className={`${textClassNames} ${closeButtonClassname}`}
          aria-label="Go back in slideout"
        >
          <CloseX className={`h-8 w-8 ${closeClassname}`} />
        </Link>
      );
    return (
      <button
        className={`${textClassNames} ${closeButtonClassname}`}
        onClick={onClose}
        aria-label="Close slideout"
      >
        <CloseX className={`h-8 w-8 ${closeClassname}`} />
      </button>
    );
  };

  const renderTopBar = () => (
    <nav className={`${slideoutClass}-topBar z-two flex items-center justify-end px-6 py-4`}>
      {headerElement}
      {renderDefaultHeader()}
    </nav>
  );

  const renderTopBarCloseFirst = () => (
    <nav className={`${slideoutClass}-topBar z-two grid grid-cols-3 items-center px-4 py-4`}>
      {renderDefaultHeader()}
      {headerElement}
    </nav>
  );

  return (
    <FocusTrap
      active={show}
      focusTrapOptions={{
        onActivate: () => toggleBodyScroll(true),
        onDeactivate: () => toggleBodyScroll(false, isSlideoutAlreadyOpen),
        returnFocusOnDeactivate: false,
      }}
    >
      <div
        id={id}
        aria-labelledby={labelId}
        role="dialog"
        aria-hidden={!show}
        className="react-modal"
      >
        <CSSTransition
          nodeRef={modalRef}
          key="drawerSlideout"
          in={show}
          timeout={200}
          classNames={slideoutClass}
          unmountOnExit
        >
          <section
            className={`${slideoutClass}-container ${slideoutContainerClassname} ${`${slideoutClass}-${desktopWidth}`}`}
            ref={modalRef}
          >
            {renderCloseFirst ? renderTopBarCloseFirst() : renderTopBar()}
            {children}
          </section>
        </CSSTransition>
        <CSSTransition
          nodeRef={overlayRef}
          key="overlaySlideout"
          in={show}
          timeout={200}
          classNames="fadeInOut"
          unmountOnExit
        >
          {backPath ? (
            <Link
              ref={overlayRef}
              className={`${slideoutClass}-background`}
              to={backPath}
              tabIndex="0"
              onClick={onClose}
            >
              <span>Go to previous slideout page</span>
            </Link>
          ) : (
            <button
              ref={overlayRef}
              className={`${slideoutClass}-background`}
              aria-label="Click dimmed background to close slideout"
              tabIndex="0"
              onClick={onClose}
            >
              <span>Close slideout</span>
            </button>
          )}
        </CSSTransition>
      </div>
    </FocusTrap>
  );
};

Slideout.propTypes = {
  backPath: PropTypes.string,
  closeButtonClassname: PropTypes.string,
  closeClassname: PropTypes.string,
  children: PropTypes.node,
  customSlideoutClass: PropTypes.string,
  id: PropTypes.string.isRequired,
  headerElement: PropTypes.node,
  keyboardAvoidingFooter: PropTypes.bool,
  labelId: PropTypes.string.isRequired,
  renderCloseFirst: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func,
  desktopWidth: PropTypes.oneOf([WIDTH_720, WIDTH_500, WIDTH_840, WIDTH_FULL]),
  scrollableRef: Types.refType,
  slideoutContainerClassname: PropTypes.string,
  isSlideoutAlreadyOpen: PropTypes.bool,
  updateAdaChat: PropTypes.bool,
};

export default Slideout;
