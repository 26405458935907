export const DEFAULT_POSITION = '0px';
export const FOOTER_HEIGHT = '66px';

export const getIcon = () => document.getElementById('ada-button-frame');
export const getWidth = string => {
  const numMatch = string.match(/\d+/);
  if (numMatch && numMatch.length > 0) return numMatch[0];
  return 0;
};

export const resetPosition = () => {
  const adaChatIcon = getIcon();

  if (adaChatIcon) {
    adaChatIcon.style.right = DEFAULT_POSITION;
    adaChatIcon.style.bottom = DEFAULT_POSITION;
  }
};

export const updateAdaIconVisibility = ({ hide, isTieredPricing = false }) => {
  const adaChatIcon = getIcon();
  if (hide && adaChatIcon) {
    adaChatIcon.style.display = 'none';
  } else if (!hide && adaChatIcon) {
    adaChatIcon.style.display = 'flex';
    if (isTieredPricing) {
      adaChatIcon.style.marginBottom = '24px';
    }
  }
};

export const updateAdaChatPosition = ({
  desktopWidth,
  keyboardAvoidingFooter,
  show,
  isMobileOrTablet,
  customRight,
  customBottom,
}) => {
  const adaChatIcon = getIcon();
  if (!show || !adaChatIcon) return;

  if (customRight || customBottom) {
    adaChatIcon.style.right = customRight || DEFAULT_POSITION;
    adaChatIcon.style.bottom = customBottom || DEFAULT_POSITION;
  } else if (isMobileOrTablet) {
    adaChatIcon.style.right = DEFAULT_POSITION;
    adaChatIcon.style.bottom = keyboardAvoidingFooter ? FOOTER_HEIGHT : DEFAULT_POSITION;
  } else {
    adaChatIcon.style.right = `${getWidth(desktopWidth)}px`;
    adaChatIcon.style.bottom = DEFAULT_POSITION;
  }
};
