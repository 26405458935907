import classNames from 'classnames';
import React, { useState, useRef, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';

import { promotionMessageKey } from 'helpers/dismissals';
import useScreenSizeConstants from 'hooks/useScreenSizeConstants';
import useSwipeable from 'hooks/useSwipeable';
import style from 'styles/components/messageCenter/PromotionCarousel';

import Promotion from './Promotion';
import PromotionArrows from './PromotionArrows';

const PromotionCarousel = ({ isMessageDismissed, onDismiss, zoneZeroPromotions }) => {
  const promotions = zoneZeroPromotions
    .filter(promo => promo.position !== null && !isMessageDismissed(promotionMessageKey(promo)))
    .sort((a, b) => (a.position > b.position ? 1 : -1));

  const [step, setStep] = useState(0);
  const ref = useRef();
  const totalPromotions = promotions.length;

  const {
    handleMoveStart,
    handleMove,
    handleMoveEnd,
    nextStep: swipeNext,
    previousStep: swipePrevious,
    toStep: swipeTo,
  } = useSwipeable({
    step: step,
    totalSteps: totalPromotions,
    successCallback: (_, step) => {
      setStep(step);
    },
  });

  const prevStep = useCallback(() => {
    setStep(step - 1);
    swipePrevious();
  }, [setStep, swipePrevious, step]);

  const nextStep = useCallback(() => {
    setStep(step + 1);
    swipeNext();
  }, [setStep, swipeNext, step]);

  const { isMobile } = useScreenSizeConstants();
  const showNext = step < promotions.length - 1;
  const showPrevious = step > 0;
  const showDots = promotions.length > 1;
  const showNavigateButtons = !isMobile && showDots;
  const transitionRef = useRef();

  const onClickDot = useCallback(
    index => {
      setStep(index);
      swipeTo(index);
    },
    [setStep, swipeTo]
  );

  return (
    <CSSTransition
      nodeRef={transitionRef}
      in={Boolean(totalPromotions)}
      timeout={100}
      classNames="fadeInOut"
      unmountOnExit
    >
      <div
        ref={transitionRef}
        className={classNames('mb-4 overflow-hidden', { shadow: totalPromotions === 1 })}
      >
        <div
          className={classNames(style.innerCarousel, style.step, style[`step${step}`])}
          onTouchStart={isMobile ? handleMoveStart : undefined}
          onTouchMove={isMobile ? handleMove : undefined}
          onTouchEnd={isMobile ? handleMoveEnd : undefined}
          onDragStart={isMobile ? handleMoveStart : undefined}
          onDrag={isMobile ? handleMove : undefined}
          onDragEnd={isMobile ? handleMoveEnd : undefined}
          onMouseDown={isMobile ? handleMoveStart : undefined}
          onMouseUp={isMobile ? handleMoveEnd : undefined}
          onMouseMove={isMobile ? handleMove : undefined}
          ref={ref}
        >
          {promotions.map((promotion, index) => (
            <div
              className={classNames('shadow', style.promo, {
                [style.singlePromo]: totalPromotions === 1,
              })}
              key={index}
            >
              <Promotion
                className={classNames('shadow', style.promo)}
                {...promotion}
                isCarousel
                onDismiss={() => {
                  onDismiss(promotionMessageKey(promotion));
                  if (step === 0) return setStep(0);
                  prevStep();
                }}
              />
            </div>
          ))}
        </div>
        {showDots && (
          <div className="pb-lg flex items-center justify-center">
            <div className={style.dots}>
              {promotions.map((_, index) => (
                <button
                  key={index}
                  onClick={() => onClickDot(index)}
                  className={classNames('m-1 h-2 w-2 rounded border-0 bg-charcoal-60 p-0', {
                    ['bg-blue-100']: step === index,
                  })}
                >
                  <span className="sr-only">{`View promotion ${index}`}</span>
                </button>
              ))}
            </div>
            {showNavigateButtons && (
              <PromotionArrows
                scrollLeft={prevStep}
                showNext={showNext}
                showPrev={showPrevious}
                scrollRight={nextStep}
              />
            )}
          </div>
        )}
      </div>
    </CSSTransition>
  );
};

PromotionCarousel.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  zoneZeroPromotions: PropTypes.array,
  isMessageDismissed: PropTypes.func.isRequired,
};

export default PromotionCarousel;
