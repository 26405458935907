import { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const IdPortal = ({ children, id }) => {
  const bodyRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    bodyRef.current = document.getElementById(id);
    setIsMounted(true);
  }, [id]);

  if (!bodyRef?.current || bodyRef?.current?.nodeType !== 1 || !isMounted) return null;

  return ReactDOM.createPortal(children, bodyRef.current);
};

IdPortal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default IdPortal;
