import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { AnalyticsProvider } from 'use-analytics';

import InternalErrorBoundary from 'components/errors/InternalErrorBoundary';
import MessageCenterContainer from 'components/messageCenter/MessageCenterContainer';
import { BrandContextProvider } from 'context/BrandContext';
import { FeatureFlagProvider } from 'context/FeatureFlagContext';
import pixelAnalytics from 'helpers/analytics/pixelAnalytics';
import { getStore } from 'store/configure';

const store = getStore();

document.addEventListener('DOMContentLoaded', () => {
  const rootElement = document.querySelector('#hc-notifications-react-app');
  const root = createRoot(rootElement);

  root.render(
    <InternalErrorBoundary>
      <Provider store={store}>
        <BrandContextProvider rootElement={rootElement}>
          <FeatureFlagProvider>
            <App rootElement={rootElement} />
          </FeatureFlagProvider>
        </BrandContextProvider>
      </Provider>
    </InternalErrorBoundary>
  );
});

const App = ({ rootElement }) => (
  <AnalyticsProvider
    instance={pixelAnalytics({
      canTrack: rootElement?.getAttribute('data-can-track') === 'true',
    })}
  >
    <MessageCenterContainer />
  </AnalyticsProvider>
);
