import { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const BodyPortal = ({ children }) => {
  const bodyRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    bodyRef.current = document.querySelector('body');
    setIsMounted(true);
  }, []);

  return isMounted && bodyRef?.current ? ReactDOM.createPortal(children, bodyRef.current) : null;
};

export default BodyPortal;
