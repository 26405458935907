/* Removes focus from an element after mouse click but not after keyboard interaction.
 * For when you need a good/clear :focus style but it doesn't make sense for it to hang around after mouse click
 * Use with care - if you need this, it might be a sign that the UX needs rethinking.
 */

const AutoBlurButton = props => {
  const { onMouseUp, children, ...restProps } = props;

  const blur = event => {
    if (event.currentTarget) event.currentTarget.blur();
  };

  const onMouseUpCallback = event => {
    if (onMouseUp) onMouseUp(event);
    blur(event);
  };

  return (
    <button {...restProps} onMouseUp={onMouseUpCallback}>
      {children}
    </button>
  );
};

export default AutoBlurButton;
